import { Fragment } from 'react';

import { Badge } from '@playbooks/interface/badges';
import { Span } from '@playbooks/interface/html';
import { FadIcon } from '@playbooks/interface/icons';
import { toCurrency } from 'utils';

const BountyBadges = ({ bounty, ...props }) => {
	return (
		<Fragment>
			{bounty.pricingType === 'free' ? (
				<Badge {...props}>
					<Span>Free</Span>
				</Badge>
			) : (
				<Badge {...props}>
					<FadIcon icon='arrow-up-right' />
					<Span>{toCurrency(bounty.meta?.netRevenue, 0)}</Span>
				</Badge>
			)}
			{bounty.visibility === 'private' && (
				<Badge {...props}>
					<FadIcon icon='lock' />
				</Badge>
			)}
		</Fragment>
	);
};

export { BountyBadges };
