import { Fragment, useEffect, useState } from 'react';

import { AccentBtn, Btn } from '@playbooks/interface/buttons';
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@playbooks/interface/modals';
import { stackParams } from 'api';
import { StackList } from 'components/stack/stack-list';
import { useAccount, useStore } from 'contexts';
import { useAction, useQuery } from 'hooks';
import { SearchForm } from 'molecules/forms';
import { Pagination } from 'molecules/paginations';
import { AlgoliaService } from 'services';
import { log } from 'utils';

const StackBtnFlowSelect = ({ model, selected, setSelected, onView, search, rootApi, onClose, onNext }) => {
	const [stacks, setStacks] = useState([]);
	const [params, setParams] = useState({ ...stackParams, status: 'active', pageSize: '10' });
	const [meta, setMeta] = useState({ page: 0, pageSize: 0, totalRecords: 0 });
	const [query, setQuery] = useState('');
	const account = useAccount();
	const store = useStore();

	// Computed
	const client = new AlgoliaService({ tableName: 'stacks' });

	// Hooks
	useEffect(() => {
		fetchData();
	}, [query, params]);

	useEffect(() => {
		log('stacks: ', stacks);
	}, [stacks]);

	// Actions
	const [fetchData, loading] = useQuery(async () => {
		const response = query ? await searchDb() : await fetchDb();
		const bounties = response.data.filter(v => v.bounties.map(r => r.id).includes(model.id));
		const repos = response.data.filter(v => v.repos.map(r => r.id).includes(model.id));
		setStacks(response.data);
		setSelected([...bounties, ...repos]);
		setMeta(response.meta);
	});

	const [onSave, saveTask] = useAction({}, async () => {
		const response = await store.updateRecord({
			url: `${rootApi}/collect`,
			data: { stackIds: selected.map(v => v.id) },
		});
		onNext(response.data);
	});

	// Methods
	const fetchDb = async () => {
		return await store.query({ url: `${account.rootApi}/stacks`, params });
	};

	const searchDb = async () => {
		return await client.queryIndex(query, { ...params, ...search });
	};

	const onAdd = () => {
		onView('form');
	};

	const onSelect = value => {
		if (selected.map(v => v.id).includes(value.id)) {
			return setSelected(selected.filter(v => v.id !== value.id));
		}
		return setSelected(selected.concat(value));
	};

	// Render
	return (
		<Fragment>
			<ModalHeader onClose={onClose}>
				<ModalTitle>Add to Stack</ModalTitle>
			</ModalHeader>

			<ModalBody border='border-b' display='flex-between' space='space-x-4'>
				<SearchForm
					id='stack_search'
					placeholder='Search my stacks...'
					query={query}
					setQuery={setQuery}
					tailwind={{ inputGroup: { flex: 'grow' }, input: { spacing: 'px-0 py-2.5' } }}
				/>
				<Btn size='icon' icon='plus' onClick={onAdd} />
			</ModalBody>

			<ModalBody size='md' spacing=''>
				<StackList
					type='select'
					stacks={stacks}
					tailwind={{ feedback: { spacing: 'mx-4' }, grid: { gap: '' } }}
					selected={selected}
					loading={loading}
					onClick={onSelect}
				/>
			</ModalBody>

			<ModalBody border='' display='flex-between' space='space-x-4'>
				<Pagination meta={meta} params={params} setParams={setParams} />
			</ModalBody>

			<ModalFooter>
				<AccentBtn onClick={onClose}>Close</AccentBtn>
				<Btn taskRunning={saveTask.running} onClick={onSave}>
					Save
				</Btn>
			</ModalFooter>
		</Fragment>
	);
};

export { StackBtnFlowSelect };
