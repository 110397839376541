import { Fragment, useEffect, useState } from 'react';

import { AccentBtn, BorderBtn, Btn } from '@playbooks/interface/buttons';
import { Form } from '@playbooks/interface/forms';
import { Span } from '@playbooks/interface/html';
import { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@playbooks/interface/modals';
import { StackAbbreviatedFields } from 'components/stack/stack-abbreviated-fields';
import { useAccount, useStore } from 'contexts';
import { useSave } from 'hooks';
import { log } from 'utils';

const StackBtnFlowForm = ({ model, rootApi, onClose, onPrev, onNext }) => {
	const [stack, setStack] = useState<any>({});
	const account = useAccount();
	const store = useStore();

	// Hooks
	useEffect(() => {
		if (open) setStack(model);
	}, [open]);

	useEffect(() => {
		log('stack: ', stack);
	}, [stack]);

	// Actions
	const [onSave, saveTask] = useSave(stack, 'stack', async () => {
		const response = await store.saveRecord({
			url: `${account.rootApi}/stacks`,
			data: stack,
		});
		onNext(response.data);
	});

	// Methods
	const onSubmit = e => {
		e.preventDefault();
		onSave();
	};

	// Render
	return (
		<Fragment>
			<Form onSubmit={onSubmit}>
				<ModalHeader onClose={onClose}>
					<ModalTitle>
						<BorderBtn size='icon' fontSize='text-lg' icon='chevron-left' onClick={onPrev} />
						<Span>Add Stack</Span>
					</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<StackAbbreviatedFields stack={stack} setStack={setStack} />
				</ModalBody>
				<ModalFooter>
					<AccentBtn onClick={onPrev}>Cancel</AccentBtn>
					<Btn type='submit' taskRunning={saveTask.running}>
						Save
					</Btn>
				</ModalFooter>
			</Form>
		</Fragment>
	);
};

export { StackBtnFlowForm };
