import { BorderLink } from '@playbooks/interface/links';
import { StackBadges } from 'components/stack/stack-badges';
import { StackCardActions } from 'components/stack/stack-card-actions';
import { StackCardHeader } from 'components/stack/stack-card-header';
import { StackCardMeta } from 'components/stack/stack-card-meta';
import {
	ActionCard,
	DetailCard,
	DisplayCard,
	GridCard,
	InfoCard,
	ListCard,
	PhotoLinkCard,
	PillCard,
	PreviewCard,
	SearchBtnCard,
	SelectCard,
} from 'molecules/cards';
import { formatDate } from 'utils';

export const StackActionCard = ({ stack, loading, children, tailwind }) => {
	// Render
	return (
		<ActionCard
			icon='hand-point-up'
			photo={stack.thumbnail}
			title={stack.name}
			subtitle={stack.description}
			meta={<StackCardMeta type='card' stack={stack} />}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</ActionCard>
	);
};

export const StackDetailCard = ({ stack, loading, tailwind }) => {
	// Render
	return (
		<DetailCard
			icon='layer-group'
			status={stack.status}
			cover={stack.cover}
			photo={stack.thumbnail}
			title={stack.name}
			subtitle={stack.owner?.name}
			text={stack.description}
			footer={stack.updatedAt}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const StackDisplayCard = ({ stack, rootLink, children, tailwind }) => {
	// Render
	return (
		<DisplayCard
			icon='layer-group'
			header={<StackCardHeader type='display' stack={stack} />}
			badges={<StackBadges stack={stack} />}
			title={stack.name}
			subtitle={stack.description}
			href={`${rootLink}/stacks/${stack.uuid}`}
			meta={<StackCardMeta type='grid' stack={stack} />}
			tailwind={tailwind}>
			{children ? children : <StackCardActions type='grid' stack={stack} rootLink={rootLink} />}
		</DisplayCard>
	);
};

export const StackGridCard = ({ stack, loading, rootLink, tailwind }) => {
	// Render
	return (
		<GridCard
			icon='layer-group'
			header={<StackCardHeader type='display' stack={stack} />}
			status={stack.visibility}
			title={stack.name}
			subtitle={stack.description}
			text={stack.description}
			href={`${rootLink}/stacks/${stack.uuid}`}
			meta={<StackCardMeta type='grid' stack={stack} loading={loading} />}
			tailwind={tailwind}>
			<StackCardActions type='grid' stack={stack} rootLink={rootLink} />
		</GridCard>
	);
};

export const StackInfoCard = ({ stack, loading, tailwind }) => {
	// Render
	return (
		<InfoCard
			icon='layer-group'
			status={stack.status}
			photo={stack.thumbnail}
			title={stack.name}
			subtitle={stack.description}
			meta={<StackCardMeta type='card' stack={stack} />}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const StackListCard = ({ stack, loading, onClick, rootLink, tailwind }) => {
	// Render
	return (
		<ListCard
			icon='layer-group'
			header={<StackCardHeader type='list' stack={stack} />}
			status={stack.visibility}
			title={stack.name}
			subtitle={stack.description}
			href={`${rootLink}/stacks/${stack.uuid}`}
			meta={<StackCardMeta type='list' stack={stack} loading={loading} />}
			loading={loading}
			tailwind={tailwind}>
			<StackCardActions type='list' stack={stack} rootLink={rootLink} />
		</ListCard>
	);
};

export const StackPhotoLinkCard = ({ stack, loading, rootLink, children, tailwind }) => {
	// Render
	return (
		<PhotoLinkCard
			icon='layer-group'
			photo={stack.thumbnail}
			title={stack.name}
			subtitle={stack.owner?.name}
			href={`${rootLink}/stacks/${stack.uuid}`}
			tailwind={tailwind}>
			{children}
		</PhotoLinkCard>
	);
};

export const StackPillCard = ({ stack, onClick, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PillCard
			icon='layer-group'
			header={<StackCardHeader type='pill' stack={stack} />}
			title={stack.name}
			subtitle={stack.owner?.name}
			href={stack.webPath}
			tailwind={tailwind}
		/>
	);
};

export const StackPreviewCard = ({ stack, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PreviewCard
			icon='layer-group'
			header={<StackCardHeader type='preview' stack={stack} />}
			title={stack.name}
			text={stack.owner?.name}
			href={`${rootLink}/stacks/${stack.uuid}`}
			tailwind={tailwind}>
			<BorderLink size='icon' icon='chevron-right' href={`${rootLink}/stacks/${stack.uuid}`} />
		</PreviewCard>
	);
};

export const StackSearchCard = ({ stack, onClick, tailwind }) => {
	// Render
	return (
		<SearchBtnCard
			icon='layer-group'
			modelName='stack'
			badges={<StackBadges size='xs' stack={stack} />}
			photo={stack.thumbnail}
			title={stack.name}
			subtitle={stack.owner?.name}
			onClick={() => onClick(stack)}
			tailwind={tailwind}
		/>
	);
};

export const StackSelectCard = ({ stack, selected = [], onClick, tailwind }) => {
	// Render
	return (
		<SelectCard
			icon='layer-group'
			header={<StackCardHeader type='select' stack={stack} />}
			badges={<StackBadges size='xs' stack={stack} />}
			title={stack.name}
			text={`${stack.meta?.totalItems} items`}
			selected={selected.map(v => v.id).includes(stack.id)}
			onClick={() => onClick(stack)}
			tailwind={tailwind}
		/>
	);
};

export const StackSubmitCard = ({ stack, taskRunning, onClick, tailwind }) => {
	// Render
	return (
		<ActionCard
			icon='layer-group'
			badges={<StackBadges size='xs' stack={stack} />}
			status={stack.status}
			photo={stack.thumbnail}
			title={stack.name}
			text={'Last updated ' + formatDate(stack.updatedAt)}
			tailwind={tailwind}>
			<StackCardActions type='submit' stack={stack} taskRunning={taskRunning} onClick={onClick} />
		</ActionCard>
	);
};
