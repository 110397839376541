import {
	StackDisplayCard,
	StackGridCard,
	StackListCard,
	StackPhotoLinkCard,
	StackPillCard,
	StackPreviewCard,
	StackSelectCard,
	StackSubmitCard,
} from 'components/stack/stack-card';
import { List } from 'molecules/lists';

const StackList = ({ type = 'list', icon = 'layer-group', title = 'stacks', text = '', stacks, ...listProps }) => {
	const props = { type, icon, title, text, ...listProps };

	const renderItem = props => {
		switch (type) {
			case 'display':
				return <StackDisplayCard stack={props.item} {...props} />;

			case 'grid':
				return <StackGridCard stack={props.item} {...props} />;

			case 'list':
				return <StackListCard stack={props.item} {...props} />;

			case 'photo':
				return <StackPhotoLinkCard stack={props.item} {...props} />;

			case 'pill':
				return <StackPillCard stack={props.item} {...props} />;

			case 'preview':
				return <StackPreviewCard stack={props.item} {...props} />;

			case 'select':
				return <StackSelectCard stack={props.item} {...props} />;

			case 'submit':
				return <StackSubmitCard stack={props.item} {...props} />;
		}
	};

	// Render
	return <List data={stacks} renderItem={renderItem} {...props} />;
};

export { StackList };
