import { Fragment } from 'react';

import { Badge } from '@playbooks/interface/badges';
import { FadIcon } from '@playbooks/interface/icons';

const StackBadges = ({ stack, ...props }) => {
	return (
		<Fragment>
			{stack.visibility === 'private' && (
				<Badge {...props}>
					<FadIcon icon='lock' />
				</Badge>
			)}
		</Fragment>
	);
};

export { StackBadges };
