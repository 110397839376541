import { Fragment } from 'react';

import { BorderBtn } from '@playbooks/interface/buttons';
import { Span } from '@playbooks/interface/html';
import { BorderLink } from '@playbooks/interface/links';
import { BookmarkBtn } from 'components/bookmark/bookmark-btn';
import { OwnerAvatar } from 'components/owner/owner-avatar';
import { useAccount } from 'contexts';

export const StackCardActions = ({ type, stack, taskRunning, onClick, rootLink }) => {
	const account = useAccount();
	const isOwner = account.account?.id === stack.ownerId && account.type === stack.ownerType;
	const props = { stack, isOwner, taskRunning, onClick, rootLink };

	switch (type) {
		case 'grid':
			return <StackGridCardActions {...props} />;

		case 'list':
			return <StackListCardActions {...props} />;

		case 'submit':
			return <StackSubmitCardActions {...props} />;
	}
};

export const StackGridCardActions = ({ stack, isOwner, rootLink }) => (
	<Fragment>
		<OwnerAvatar type='card' variant={stack.ownerType} owner={stack.owner} />
		<Span display='flex-end' space='space-x-4'>
			<BookmarkBtn type='card' model={stack} rootApi={`/stacks/${stack.uuid}`} />
		</Span>
	</Fragment>
);

export const StackListCardActions = ({ stack, isOwner, rootLink }) => (
	<Fragment>
		<BookmarkBtn type='card' model={stack} rootApi={`/stacks/${stack.uuid}`} />
	</Fragment>
);

export const StackSubmitCardActions = ({ stack, taskRunning, onClick }) => (
	<Fragment>
		<BorderBtn
			size='icon'
			icon='trash'
			color='red-500'
			taskRunning={taskRunning.id === stack.id}
			onClick={() => onClick(stack)}
		/>
		<BorderLink size='icon' icon='pencil' href={`/add/stack/${stack.uuid}`} />
	</Fragment>
);
