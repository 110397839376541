import { useEffect, useState } from 'react';

import { Modal } from '@playbooks/interface/modals';
import { StackBtnFlowForm } from 'components/stack/stack-btn-flow-form';
import { StackBtnFlowSelect } from 'components/stack/stack-btn-flow-select';
import { useAccount } from 'contexts';

const StackBtnFlow = ({ type, model, open, setOpen, rootApi, onNext }) => {
	const [selected, setSelected] = useState([]);
	const [view, setView] = useState('select');
	const account = useAccount();

	// Computed
	const data = type === 'bounties' ? { bounties: [{ id: model.id }] } : { repos: [{ id: model.id }] };

	// Hooks
	useEffect(() => {
		if (open) setSelected(model.stacks || []);
	}, [open]);

	useEffect(() => {
		if (open) setView('select');
	}, [open]);

	// Methods
	const onClose = () => {
		setOpen(false);
	};

	const onFinish = data => {
		setSelected(selected.concat(data));
		setView('select');
	};

	// Render
	return (
		<Modal open={open} onClose={onClose}>
			{view === 'select' && (
				<StackBtnFlowSelect
					model={model}
					selected={selected}
					setSelected={setSelected}
					search={{ 'owner.uuid': account.account.uuid, ownerType: account.type }}
					rootApi={rootApi}
					onView={setView}
					onClose={onClose}
					onNext={onNext}
				/>
			)}
			{view === 'form' && (
				<StackBtnFlowForm
					model={{ status: 'active', variant: type, visibility: 'public', ...data }}
					rootApi={rootApi}
					onClose={onClose}
					onPrev={() => setView('select')}
					onNext={onFinish}
				/>
			)}
		</Modal>
	);
};

export { StackBtnFlow };
