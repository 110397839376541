import { Div } from '@playbooks/interface/html';
import { AccentLink } from '@playbooks/interface/links';
import { metaCardProps } from '@playbooks/interface/utils';

export const StackCardMeta = ({ stack, ...props }) => {
	return (
		<Div display='flex-start flex-wrap' spacing='py-1' {...props}>
			{stack.platform && (
				<AccentLink href={`/platforms/${stack.platform?.uuid}`} {...metaCardProps}>
					{stack.platform?.name}
				</AccentLink>
			)}
			{stack.language && (
				<AccentLink href={`/languages/${stack.language?.uuid}`} {...metaCardProps}>
					{stack.language?.name}
				</AccentLink>
			)}
			{stack.framework && (
				<AccentLink href={`/frameworks/${stack.framework?.uuid}`} {...metaCardProps}>
					{stack.framework?.name}
				</AccentLink>
			)}
			{stack.tool && (
				<AccentLink href={`/tools/${stack.tool?.uuid}`} {...metaCardProps}>
					{stack.tool?.name}
				</AccentLink>
			)}
			{stack.topic && (
				<AccentLink href={`/topics/${stack.topic?.uuid}`} {...metaCardProps}>
					{stack.topic?.name}
				</AccentLink>
			)}
		</Div>
	);
};
