import { Fragment } from 'react';

import { FormGroup, FormInput, FormLabel } from '@playbooks/interface/forms';
import { Col, Grid } from '@playbooks/interface/grid';
import { Radio } from '@playbooks/interface/radios';

const StackAbbreviatedFields = ({ stack, setStack }) => {
	// Render
	return (
		<Fragment>
			<FormGroup>
				<FormLabel>Name</FormLabel>
				<FormInput value={stack.name || ''} onChange={e => setStack({ ...stack, name: e.target.value })} />
			</FormGroup>

			<FormGroup spacing='py-2'>
				<Grid>
					<Col span='6'>
						<Radio
							title='Public'
							text='Show on the public marketplace.'
							value={stack.visibility === 'public'}
							onClick={() => setStack({ ...stack, visibility: 'public' })}
						/>
					</Col>
					<Col span='6'>
						<Radio
							title='Private'
							text='Hide from public view.'
							value={stack.visibility === 'private'}
							onClick={() => setStack({ ...stack, visibility: 'private' })}
						/>
					</Col>
				</Grid>
			</FormGroup>
		</Fragment>
	);
};

export { StackAbbreviatedFields };
