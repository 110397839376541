import { Fragment, useEffect, useState } from 'react';

import { AuthBtn } from 'components/auth/auth-btn';
import { StackBtnFlow } from 'components/stack/stack-btn-flow';
import { useAccount } from 'contexts';

export const StackBtn = ({ type = 'card', model, variant, rootApi }) => {
	const [stack, setStack] = useState<any>({});
	const [open, setOpen] = useState(false);
	const account = useAccount();

	// Hooks
	useEffect(() => {
		setStack(model?.social?.stack || {});
	}, [model?.social]);

	// Methods
	const onClick = () => setOpen(true);

	const onRefresh = data => {
		setOpen(false);
		setStack(data?.social?.stack || {});
	};

	const props = { model, stack, variant, open, setOpen, onClick, rootApi, onNext: onRefresh };

	// Render
	switch (type) {
		case 'card':
			return <StackCardBtn {...props} />;
		case 'hero':
			return <StackHeroBtn {...props} />;
	}
};

export const StackCardBtn = ({ model, stack, variant, open, setOpen, rootApi, onClick, onNext, ...tailwind }) => (
	<Fragment>
		<AuthBtn
			type='border'
			size='icon'
			icon='layer-group'
			alt='stack button'
			active={stack.id}
			onClick={onClick}
			{...tailwind}
		/>
		<StackBtnFlow type={variant} model={model} open={open} setOpen={setOpen} rootApi={rootApi} onNext={onNext} />
	</Fragment>
);

export const StackHeroBtn = ({ model, stack, variant, open, setOpen, rootApi, onClick, onNext, ...tailwind }) => (
	<Fragment>
		<AuthBtn type='border' prevIcon='layer-group' alt='stack button' active={stack.id} onClick={onClick} {...tailwind}>
			Collect
		</AuthBtn>
		<StackBtnFlow model={model} open={open} setOpen={setOpen} rootApi={rootApi} onNext={onNext} />
	</Fragment>
);
