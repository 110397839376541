import { BorderLink } from '@playbooks/interface/links';
import { BountyBadges } from 'components/bounty/bounty-badges';
import { BountyCardActions } from 'components/bounty/bounty-card-actions';
import { BountyCardMeta } from 'components/bounty/bounty-card-meta';
import {
	ActionCard,
	DetailCard,
	DisplayCard,
	InfoCard,
	ListCard,
	PreviewActionCard,
	PreviewCard,
	SearchBtnCard,
	SelectCard,
} from 'molecules/cards';
import { formatDate, toCurrency } from 'utils';

export const BountyActionCard = ({ bounty, loading, children, tailwind }) => {
	// Render
	return (
		<ActionCard
			icon='sack-dollar'
			photo={bounty.thumbnail}
			status={`${toCurrency(bounty.meta?.netRevenue, 0)} Raised`}
			title={bounty.name}
			subtitle={bounty.tagline}
			meta={<BountyCardMeta type='card' bounty={bounty} />}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</ActionCard>
	);
};

export const BountyDetailCard = ({ bounty, loading, tailwind }) => {
	// Render
	return (
		<DetailCard
			icon='sack-dollar'
			status={bounty.status}
			cover={bounty.cover}
			photo={bounty.thumbnail}
			pretitle={bounty.variant}
			title={bounty.name}
			subtitle={bounty.owner?.name}
			text={bounty.tagline}
			footer={bounty.updatedAt}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const BountyDisplayCard = ({ bounty, rootLink, children, tailwind }) => {
	// Render
	return (
		<DisplayCard
			icon='sack-dollar'
			badges={<BountyBadges bounty={bounty} />}
			photo={bounty.thumbnail}
			status={bounty.status}
			pretitle={bounty.variant}
			title={bounty.name}
			subtitle={bounty.tagline}
			meta={<BountyCardMeta type='card' bounty={bounty} />}
			href={`${rootLink}/bounties/${bounty.uuid}`}
			tailwind={tailwind}>
			{children ? children : <BountyCardActions type='display' bounty={bounty} rootLink={rootLink} />}
		</DisplayCard>
	);
};

export const BountyFeaturedCard = ({ bounty, loading, rootLink, tailwind }) => {
	// Render
	return (
		<FeaturedCard
			icon='sack-dollar'
			photo={bounty.thumbnail}
			title={bounty.name}
			subtitle={bounty.tagline}
			href={`${rootLink}/bounties/${bounty.uuid}`}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const BountyInfoCard = ({ bounty, loading, tailwind }) => {
	// Render
	return (
		<InfoCard
			icon='sack-dollar'
			photo={bounty.thumbnail}
			status={bounty.status}
			pretitle={bounty.variant}
			title={bounty.name}
			subtitle={bounty.tagline}
			meta={<BountyCardMeta type='card' bounty={bounty} />}
			loading={loading}
			tailwind={tailwind}
		/>
	);
};

export const BountyListCard = ({ bounty, rootLink, tailwind }) => {
	// Render
	return (
		<ListCard
			icon='sack-dollar'
			badges={<BountyBadges size='xs' bounty={bounty} />}
			photo={bounty.thumbnail}
			title={bounty.name}
			subtitle={bounty.tagline}
			meta={<BountyCardMeta type='list' bounty={bounty} />}
			href={`${rootLink}/bounties/${bounty.uuid}`}
			tailwind={tailwind}>
			<BountyCardActions type='list' bounty={bounty} rootLink={rootLink} />
		</ListCard>
	);
};

export const BountyPreviewCard = ({ bounty, loading, rootLink, tailwind }) => {
	// Render
	return (
		<PreviewCard
			icon='sack-dollar'
			photo={bounty.thumbnail}
			title={bounty.name}
			text={bounty.uuid}
			href={`${rootLink}/bounties/${bounty.uuid}`}
			loading={loading}
			tailwind={tailwind}>
			<BorderLink size='icon' icon='chevron-right' href={`${rootLink}/bounties/${bounty.uuid}`} />
		</PreviewCard>
	);
};

export const BountyPreviewActionCard = ({ bounty, loading, children, tailwind }) => {
	// Render
	return (
		<PreviewActionCard
			icon='sack-dollar'
			photo={bounty.thumbnail}
			title={bounty.name}
			text={bounty.tagline}
			loading={loading}
			tailwind={tailwind}>
			{children}
		</PreviewActionCard>
	);
};

export const BountySearchCard = ({ bounty, onClick, tailwind }) => {
	// Render
	return (
		<SearchBtnCard
			icon='sack-dollar'
			modelName='bounty'
			badges={<BountyBadges size='xs' bounty={bounty} />}
			photo={bounty.thumbnail}
			title={bounty.name}
			subtitle={bounty.tagline}
			onClick={() => onClick(bounty)}
			tailwind={tailwind}
		/>
	);
};

export const BountySelectCard = ({ bounty, selected = [], taskRunning, onClick, tailwind }) => {
	// Render
	return (
		<SelectCard
			icon='sack-dollar'
			badges={<BountyBadges size='xs' bounty={bounty} />}
			status={bounty.status}
			photo={bounty.thumbnail}
			title={bounty.name}
			subtitle={bounty.owner?.name}
			selected={selected.map(v => v.id).includes(bounty.id)}
			taskRunning={bounty.id === taskRunning}
			onClick={() => onClick(bounty)}
			tailwind={tailwind}
		/>
	);
};

export const BountySubmitCard = ({ bounty, taskRunning, onClick, tailwind }) => {
	// Render
	return (
		<ActionCard
			icon='sack-dollar'
			badges={<BountyBadges size='xs' bounty={bounty} />}
			status={bounty.status}
			photo={bounty.thumbnail}
			pretitle={bounty.variant}
			title={bounty.name}
			text={'Last updated ' + formatDate(bounty.updatedAt)}
			tailwind={tailwind}>
			<BountyCardActions type='submit' bounty={bounty} taskRunning={taskRunning} onClick={onClick} />
		</ActionCard>
	);
};
