import { Fragment } from 'react';

import { BorderBtn } from '@playbooks/interface/buttons';
import { Span } from '@playbooks/interface/html';
import { BorderLink } from '@playbooks/interface/links';
import { BookmarkBtn } from 'components/bookmark/bookmark-btn';
import { OwnerAvatar } from 'components/owner/owner-avatar';
import { StackBtn } from 'components/stack/stack-btn';
import { useAccount } from 'contexts';

export const BountyCardActions = ({ type, bounty, taskRunning, rootLink, onClick }) => {
	const account = useAccount();
	const isOwner = account.account?.id === bounty.ownerId && account.type === bounty.ownerType;
	const props = { bounty, taskRunning, isOwner, rootLink, onClick };

	// Render
	switch (type) {
		case 'display':
			return <BountyDisplayCardActions {...props} />;

		case 'list':
			return <BountyListCardActions {...props} />;

		case 'submit':
			return <BountySubmitCardActions {...props} />;
	}
};

export const BountyDisplayCardActions = ({ bounty }) => (
	<Fragment>
		<OwnerAvatar type='card' variant={bounty.ownerType} owner={bounty.owner} />
		<Span display='flex-start' space='space-x-4'>
			<StackBtn type='card' model={bounty} variant='bounties' rootApi={`/bounties/${bounty.uuid}`} />
			<BookmarkBtn type='card' model={bounty} rootApi={`/bounties/${bounty.uuid}`} />
		</Span>
	</Fragment>
);

export const BountyListCardActions = ({ bounty }) => (
	<Fragment>
		<StackBtn type='card' model={bounty} variant='bounties' rootApi={`/bounties/${bounty.uuid}`} />
		<BookmarkBtn type='card' model={bounty} rootApi={`/bounties/${bounty.uuid}`} />
	</Fragment>
);

export const BountySubmitCardActions = ({ bounty, taskRunning, onClick }) => (
	<Fragment>
		<BorderBtn
			size='icon'
			icon='trash'
			color='red-500'
			taskRunning={taskRunning.id === bounty.id}
			onClick={() => onClick(bounty)}
		/>
		<BorderLink size='icon' icon='pencil' href={`/add/bounty/${bounty.uuid}`} />
	</Fragment>
);
